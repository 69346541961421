import React from 'react';
import TripGuide from '../../../../components/guides/TripComponent';
import Footer from '../../../../components/FooterComponent';

import { Highlands } from '../../../../guides/countries/scotland/highlands';

function HighlandsPage(props) {
    return (
        <div>
            <TripGuide trip={ Highlands } pathname={ props.location.pathname } urlBack="/travel-guides/europe/scotland" />
            <Footer />
        </div>
    );
}

export default HighlandsPage;
