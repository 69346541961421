import React, { Component } from 'react';
import './Guides.css';

import { Nav, Navbar, NavItem, NavLink, NavbarToggler, NavbarBrand, Collapse } from 'reactstrap';
import { Link } from 'gatsby';
import { Figure } from 'react-bootstrap';
import Zoom from 'react-reveal/Zoom';

import AdjustableImage from '../elements/AdjustableImage';
import TopIcon from '../elements/TopIcon';
import RenderEssentials from '../elements/render/RenderEssentials';
import RenderRestaurants from '../elements/render/RenderRestaurants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faUtensils } from '@fortawesome/free-solid-svg-icons';

import PlaceMetadata from '../../metadata/PlaceMetadata';

class TripComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavOpen: false,
            isItineraryVisible: false,
            itineraryPos: 0
        }

        this.toggleNav = this.toggleNav.bind(this);
        this.closeNav = this.closeNav.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

        this.topRef = React.createRef();
        this.descriptionRef = React.createRef();
        this.essentialsRef = React.createRef();
        this.itineraryRef = React.createRef();
        this.itineraryTopRef = React.createRef();

        this.props.trip.itinerary.days.forEach((day, pos) => {
            this["trip" + pos.toString()] = React.createRef();
        });
    }

    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        var itTop = this.itineraryTopRef.current.getBoundingClientRect().top;

        if (itTop > 70) {
            this.setState({ isItineraryVisible: false });
        } else {
            this.setState({ isItineraryVisible: true });
        }

        this.props.trip.itinerary.days.forEach((day, pos) => {
            var top = this["trip" + pos.toString()].current.getBoundingClientRect().top;

            if (top > 0 && top < 150) {
                this.setState({ itineraryPos: pos });
            } else if (top > window.innerHeight * 3 / 4 && top < window.innerHeight + 20) {
                var position = pos === 0 ? 0 : pos - 1;
                this.setState({ itineraryPos: position });
            }
        });
    }

    toggleNav() {
        this.setState({ isNavOpen: !this.state.isNavOpen });
    }

    closeNav() {
        this.setState({ isNavOpen: false });
    }

    render() {
        const RenderItinerary = ({ itinerary }) => {
            return itinerary.map((day, pos) => {
                return (
                    <div key={ day.title } className="trip-itinerary" ref={ this["trip" + pos.toString()] } >
                        <h2>{ day.title }</h2>
                        <h5>{ day.subtitle }</h5>
                        <div>{ day.description }</div>
                        { day.restaurants &&
                            <div className="ms-md-3 me-md-3" style={{ marginTop: '30px', marginBottom: '10px' }}>
                                <table>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}>
                                            <FontAwesomeIcon icon={ faUtensils } style={{ fontSize: '30px', marginRight: '12px' }} />
                                        </td>
                                        <td style={{ verticalAlign: 'top' }}>
                                            <span style={{ fontSize: '1.1em', marginRight: '10px' }}>Restaurant Suggestions:</span>
                                        </td>
                                        <td>
                                            {
                                                day.restaurants.map((restaurant, pos) => {
                                                    return (
                                                        <div key={ pos } style={{ marginBottom: '2px' }}>
                                                            <a href={ restaurant.link } target="_blank" rel="noopener noreferrer">{ restaurant.name } <i>({ '$'.repeat(restaurant.price) })</i></a>{ restaurant.description && <React.Fragment>, { restaurant.description }</React.Fragment> }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        }
                        { day.accommodation &&
                            <div className="ms-md-3 me-md-3" style={{ marginTop: '30px', marginBottom: '10px' }}>
                                <table>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}>
                                            <FontAwesomeIcon icon={ faBed } style={{ fontSize: '30px', marginRight: '12px' }} />
                                        </td>
                                        <td style={{ verticalAlign: 'top' }}>
                                            <span style={{ fontSize: '1.1em' }}>Where To Stay:</span>
                                        </td>
                                    </tr>
                                </table>
                                <div className="row">
                                    <RenderRestaurants restaurants={ day.accommodation } />
                                </div>
                            </div>
                        }
                        <img style={{ marginTop: "20px", marginBottom: '10px' }} width="100%" src={ day.image } alt={ day.caption } loading="lazy" fluid="true" />
                        <Figure.Caption className="text-center">{ day.caption }</Figure.Caption>
                    </div>
                )
            });
        }

        return (
            <React.Fragment>
                <PlaceMetadata title={ this.props.trip.name } description={ this.props.trip.brief } image={ this.props.trip.image }
                    pathname={ this.props.pathname } />
                <TopIcon />
                <div id="page-top" ref={ this.topRef } className='header-topic'>
                    <AdjustableImage image={ this.props.trip.image } thumb={ this.props.trip.thumb } name={ this.props.trip.name } />
                    <div className="header-text">
                        <p>{ this.props.trip.country }</p>
                        <h1 className="header-title">{ this.props.trip.name }</h1>
                        <p className="header-caption">{ this.props.trip.caption }</p>
                    </div>
                </div>

                <Navbar light sticky="top" className="bg-light border" expand="sm">
                    <div className="container">
                        <NavbarBrand className="d-block d-sm-none" href="" onClick={() => {
                                this.topRef.current.scrollIntoView();
                                this.closeNav(); }}>
                            { this.props.trip.name }
                        </NavbarBrand>
                        <NavbarToggler onClick={ this.toggleNav } />
                        <Collapse isOpen={ this.state.isNavOpen } navbar >
                            <Nav navbar>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.descriptionRef.current.scrollIntoView();
                                            this.closeNav(); }}>Description</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.essentialsRef.current.scrollIntoView();
                                            this.closeNav(); }}>Essentials</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.itineraryRef.current.scrollIntoView();
                                            this.closeNav(); }}>Itinerary</NavLink>
                                </NavItem>
                            </Nav>
                            <Nav className="ms-auto" navbar>
                                <NavItem>
                                    <NavLink tag={ Link } to={ this.props.urlBack }>{ this.props.trip.country }</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>

                <div id="trip-main-div" className="container" >
                    <div className="city-header">
                        <h1>{ this.props.trip.name } Travel Guide</h1>
                        <div className="col-12 col-sm-10 col-lg-9 m-auto">
                            { this.props.trip.brief }
                        </div>
                    </div>

                    <div className="city-text">
                        <div className="city-topic">
                            <div className="col-12 col-lg-10 ms-auto me-auto">
                                <h1 id="description" ref={ this.descriptionRef }>Description</h1>
                                { this.props.trip.description }
                            </div>
                        </div>

                        <div className="city-topic">
                            <div className="col-12 col-lg-11 ms-auto me-auto" >
                                <h1 id="essentials" ref={ this.essentialsRef }>Essentials</h1>
                                <RenderEssentials title={`How many days for ${this.props.trip.name}?`} text={this.props.trip.nrDays} />
                                <RenderEssentials title={`When To Visit ${this.props.trip.name}?`} text={this.props.trip.whenVisit} />
                                <RenderEssentials title={`How To Move Around?`} text={this.props.trip.mobility} />
                                <RenderEssentials title={`What To Bring?`} text={this.props.trip.packing} />
                                <RenderEssentials title={`Crime & Safety`} text={this.props.trip.safety} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" >
                    <div className="city-text" ref={ this.itineraryTopRef }>
                        <div className="d-none d-lg-block" style={{ position: 'fixed', top: '70px' }}>
                            <Zoom bottom when={ this.state.isItineraryVisible }>
                                { this.props.trip.itinerary.days.map((day, pos) => {
                                        let background, color;
                                        if (pos === this.state.itineraryPos) {
                                            background = '#F5F5F5'; //'#E6AA68';
                                            color = '#000000';
                                        } else {
                                            background = '#FFFFFF'; //'#6699CC';
                                            color = '#000000';
                                        }

                                        return (
                                            <div className="circle-days" style={{ background: `${background}`, color: `${color}` }}
                                                key={ pos }
                                                onClick={() => {
                                                    this["trip" + pos.toString() ].current.scrollIntoView();
                                                    this.setState({ itineraryPos: pos }); }}>
                                                <p>{ pos + 1 }</p>
                                            </div>
                                        );
                                    })
                                }
                            </Zoom>
                        </div>
                        <div className="city-topic">
                            <div className="col-12 col-lg-10 ms-auto me-auto">
                                <h1 id="itinerary" ref={ this.itineraryRef }>Itinerary</h1>
                                { this.props.trip.itinerary.description }
                                <RenderItinerary itinerary={ this.props.trip.itinerary.days } />
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default TripComponent;
