import React from 'react';
import { Figure } from 'react-bootstrap';

export const Highlands = {
    "name": "Highlands",
    "country": "Scotland",
    "continent": "Europe",
    "thumb": "https://live.staticflickr.com/65535/50979246456_e3d4b0da79_t.jpg",
    "image": "https://live.staticflickr.com/65535/50979246456_391ab2ab75_o.jpg",
    "caption": "The land of wild landscapes and countless castles",

    "brief": "The wild landscapes of the Highlands and the islands of Scotland offer one of the last corners of Europe where you can discover genuine solitude.",

    "description": <p>
        Rolling hills, rocky cliffs and long valleys dotted with a myriad of colors, crystalline lakes sparkling even
        on the coldest days, and castles scattered across the countryside make you wonder what it would be like to go back in time.
        <br/><br/>
        Legend and tradition are deeply rooted in the Highlands. Ruined forts and monastic cells were once home to Irish
        Gaelic chiefs and saints; deserted beaches and mountain passes once echoed in clashing clan battles; and empty
        valleys are still haunted by the ghosts of the Clearances.
    </p>,

    "nrDays": <p>
        The <b>Highlands</b> are probably one of the most iconic places to visit in Europe. As such, they have a lot of things to see and
        places to visit. With that in mind we suggest <b>at least 5 days</b> for your visit. Assuming you will take 5 days on your trip, prepare to
        least spend 2 days for the <a href=" /travel-guides/europe/Scotland/isle-of-skye">Isle of Skye</a>. If you have more time for this wonderful adventure, you can easily extend
        your visit to 8 or 9 days to enjoy the entire north of the country with the calm that nature deserves.
    </p>,

    "whenVisit": <p>
        As in the rest of the country the high season in during the warm months. The tourists peak is during the summer, so we
        recommend you avoid this period. We suggest to visit this part of the country from <b>late April</b> to <b>early June</b> or <b>late September</b>
    to <b>October</b>. The temperatures are still good and you will have more space for yourself.
    </p>,

    "mobility": <p>
        In our opinion freedom of movement brings a lot of benefits on the Highlands. Although you can get a guided tour and visit
        the main spots, you will not have the same chances to see everything you want and at your pace. So, the best way to move around the
        Highlands is definitely <b>by car</b>. Although driving is on the opposite side to the “normal” side of the road, you will quickly
        get used to it and you will realize the enormous advantages of being able to go to all places in your bucket list.
        We recommend <a href=" https://www.celticlegend.co.uk/car-rental-prices-scotland/" target="_blank" rel="noopener noreferrer">Celtic Legend</a> since we have had a good experience with them.
    </p>,

    "packing": <p>
        Although it is not a risky adventure, not that far from civilization or with difficult routes, there are always some important things to
        consider for this trip. Even though the mobile network is almost always good, you should download offline maps from the area since there
        will be some place where you may lose your mobile wireless network. Alternatively, in the good traditional style,
        take some <b>paper maps</b> (making the trip only with access to these maps will certainly be interesting and an
        adventure that you will remember). It may be important if the GPS fails.
        <br/><br/>
        In addition, a small <b>first aid kit</b>, <b>water</b> and some <b>food</b> are always essential goods in case of any unforeseen event along the way.
        And of course, <b>comfortable clothes and shoes</b> to trek and visit most of the many fantastic trails in the area.
        In addition, you will not regret bringing a good camera with you, you'll take great photographs.
    </p>,

    "safety": <p>
        All the Highlands are pretty safe and we didn't had any kind of trouble during our trip. Just be careful driving on remote areas
        and tiny streets, because help could take some time to arrive.
    </p>,

    "itinerary": {
        "description": <p>
            As previously mentioned, <b>5 days</b> is the bare <b>minimum</b> to enjoy most of the wonders that this land has to offer.
            For this, we recommend that you <b>rent a car</b> on the first day very early, to start the day full of energy. Next, we
            will present a suggested guide which we believe covers the most well-known and popular places. We will present
            the Edinburgh-Glasgow route, however it can be done in the opposite order if it is more practical for you.
        </p>,

        "days": [
            {
                "title": "Day 1",
                "subtitle": "East Coast and Road to Inverness",
                "image": "https://upload.wikimedia.org/wikipedia/commons/8/8f/Dunnottar_Castle_%28pro_view%29.jpg",
                "caption": "Dunnotar Castle.",
                "description": <div>
                    Starting the journey in the city of Edinburgh, we will quickly make our way north. All motorways in Scotland
                    are free of charge and that gives us a good advantage on getting around. On this first day there will be plenty of
                    hours on the road, so we recommend that you find some entertainment for the route.
                    <br/><br/>
                    The first stop will be <b>Glamis Castle</b>, considered the most beautiful castle in all of Scotland. The full tour
                    costs around £11, but you can wander around outside if you want for free. The next stop will be <b>Dunnotar Castle</b>. We
                    recommend you visit inside, but before that take a few minutes to marvel at the fantastic landscape of the castle
                    on the <b>hillside</b>. The rest of the way to <b>Inverness</b> you can stop at a <b>distillery</b> if you still have time. In the
                    evening, enjoy dinner in the city and take a walk through the <b>picturesque streets</b> of Inverness and along the
                    bank of the <b>Ness River</b>.
                </div>,
                "restaurants": [
                    {
                        "name": "The Kitchen Brasserie",
                        "price": 2,
                        "description": "Great place in the center of Inverness.",
                        "link": "https://www.tripadvisor.pt/Restaurant_Review-g186543-d1009108-Reviews-The_Kitchen_Brasserie-Inverness_Scottish_Highlands_Scotland.html"
                    },
                    {
                        "name": "The Castle Tavern",
                        "price": 2,
                        "description": "",
                        "link": "https://www.tripadvisor.pt/Restaurant_Review-g186543-d2170722-Reviews-The_Castle_Tavern-Inverness_Scottish_Highlands_Scotland.html"
                    }
                ],
                "accommodation": [
                    {
                        "name": "The King's Highway Wetherspoon",
                        "price": 2,
                        "description": "Praised for its location at the centre of Inverness, close to everything.",
                        "link": "",
                        "image": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/62163523.webp?k=1cabfe813993ae597c76411bd239bbf7112fb7f6a13ed92b3cb4197958a526bb&o="
                    },
                    {
                        "name": "Highland Apartments by Mansley",
                        "price": 3,
                        "description": "Luxurious apartments located at the banks of river Ness, right in the center of Inverness.",
                        "link": "",
                        "image": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/266970981.webp?k=2894815f8047ba6f7ebbf2488768cd4dbb42b6f39c6c19730cbdc5e467296be7&o="
                    }
                ]
            },
            {
                "title": "Day 2",
                "subtitle": "Loch Ness & Eilean Donan",
                "image": "https://images.unsplash.com/photo-1555961130-c9703f52b4d6?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1961&q=80",
                "caption": "Eilean Donan Castle.",
                "description": <div>
                    After a good night of sleep in <b>Inverness</b>, take the early morning to visit <b>St Andrew’s Cathedral</b> and the <b>Inverness Castle</b>.
                    On the latter, you will have a beautiful view of the landscape of the city. After the city tour, continue your way
                    to <b>Urquhart Castle</b>, with the majestic <b>Loch Ness</b> always nearby. Make stops along the way by the lake, to appreciate it's size and scale.
                    <br/><br/>
                    <div className="col-12 col-md-11 col-lg-10 m-auto">
                        <img width="100%" src="https://images.unsplash.com/photo-1561538433-276d3f8e0359?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1949&q=80" />
                    </div>
                    <Figure.Caption className="text-center">Urquhart Castle.</Figure.Caption>
                    <br/>
                    After spending some time enjoying the area, continue on your way to <b>Eilean Donan Castle</b>. Being the setting for several
                    films known as the <i>Highlander</i> and <i>Made of Honor</i>, this is the most photographed castle in all of Scotland.
                    You will surely understand why when you arrive at the site. On the journey to the Isle of Skye make a stop
                    at the <b>Sligachan Bridge</b> as it also has an incredible view. For the night, you can choose to stay in <b>Kyle of Lochalsh</b> or <b>Portree</b>.
                    Both cities are interesting for a short walk at night, although Portree is bigger.
                </div>,
                "restaurants": [
                    {
                        "name": "Urquhart's Restaurant",
                        "price": 2,
                        "description": "Good restaurant serving local cuisine.",
                        "link": "https://www.tripadvisor.pt/Restaurant_Review-g186543-d2230098-Reviews-Urquhart_s_Restaurant-Inverness_Scottish_Highlands_Scotland.html"
                    },
                    {
                        "name": "McBains Restaurant By The River",
                        "price": 2,
                        "description": "Offers good food at a selective range of prices.",
                        "link": "https://www.tripadvisor.pt/Restaurant_Review-g186543-d1478424-Reviews-McBains_Restaurant_By_The_River-Inverness_Scottish_Highlands_Scotland.html"
                    }
                ],
                "accommodation": [
                    {
                        "name": "Portree Independent Hostel",
                        "price": 1,
                        "description": "A cheap option for those traveling on a budget. Facilities include a shared kitchen, a shared lounge and free WiFi in all areas.",
                        "link": "",
                        "image": "https://live.staticflickr.com/65535/50848769761_eb69bd5d01_o.jpg"
                    },
                    {
                        "name": "Portree Youth Hostel",
                        "price": 1,
                        "description": "Hostel with a shared dining area and a common lounge. All rooms are heated and very comfortable, with option for a private bathroom.",
                        "link": "",
                        "image": "https://live.staticflickr.com/65535/50848858847_8b77f4c8e9_o.jpg"
                    },
                    {
                        "name": "Cuillin Hills Hotel",
                        "price": 3,
                        "description": "This luxurious and very expensive hotel has spectacular views over Portree Bay and the Cuillin Mountain Range. It features an award-winning restaurant and serves a variety of whiskeys.",
                        "link": "",
                        "image": "https://live.staticflickr.com/65535/50848769726_ea43cebf99_o.jpg"
                    },
                    {
                        "name": "Raasay House Hotel",
                        "price": 0,
                        "description": "Located at Isle of Raasay, this is a magnificent award-winning hotel. It offers rooms at a very large selection of prices. It is necessary to use the ferry to reach the island.",
                        "link": "",
                        "image": "https://live.staticflickr.com/65535/50848858777_11cf711ef3_o.jpg"
                    }
                ]
            },
            {
                "title": "Day 3",
                "subtitle": "Isle of Skye & Trotternish Loop",
                "image": "https://images.unsplash.com/photo-1589489604070-33c78761d718?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
                "caption": "Old Man of Storr.",
                "description": <div>
                    With so much to see, the <a href="/travel-guides/europe/scotland/isle-of-skye">Isle of Skye</a> will certainly be a place that will fill your days to the limit.
                    For a better analysis of the whole island you can read our specific travel guide for the <a href="/travel-guides/europe/scotland/isle-of-skye">Isle of Skye</a>.
                    <br/><br/>
                    However, we will also present here the recommended route. For the 5-day visit to the Highlands we recommend
                    that at least 1.5 / 2 days should be dedicated to this area. So, if you stayed the night before in Kyle of Lochalsh,
                    we will start our journey with a visit to the <b>Fairy Pools</b>. If it is in Portree we will save this stop for the end and
                    start our route in the <b>Fairy Glen</b>, which will provide you with a short walk along the place. Continuing the route, your next spot
                    will be <b>Quiraing</b>, where you will find some amazing trails, up to 6km. After, move forward to the <b>Kilt Rock and Mealt Falls</b>,
                    where you will be able to appreciate a giant waterfall and amazing views of the surrounding cliffs.
                    <br/><br/>
                    Continuing your journey, we suggest that you visit a hidden gem of the island, certainly very worthwhile,
                    the <b>Brother’s Point</b>. <b>The Old Man of Storr</b> is the next stop and be prepared for a long way up
                    to get to the top. If you have time, enjoy the sunset at the <b>Neist Point</b>. Since you have a lot of activities
                    for this day, we recommend that you grab a quick lunch to optimize time.
                </div>,
                "restaurants": [
                    {
                        "name": "The Red Brick Cafe",
                        "price": 1,
                        "description": "Quite famous for providing good breakfasts",
                        "link": "https://www.tripadvisor.com/Restaurant_Review-g551883-d7247446-Reviews-The_Red_Brick_Cafe-Portree_Isle_of_Skye_The_Hebrides_Scotland.html"
                    },
                    {
                        "name": "Pizza in the Skye",
                        "price": 1,
                        "description": "Fantastic pizza for take away.",
                        "link": "https://www.tripadvisor.com/Restaurant_Review-g551883-d18850460-Reviews-Pizza_in_the_Skye-Portree_Isle_of_Skye_The_Hebrides_Scotland.html"
                    },
                    {
                        "name": "Claymore Restaurant",
                        "price": 3,
                        "description": "",
                        "link": "https://www.tripadvisor.com/Restaurant_Review-g551872-d1536321-Reviews-Claymore_Restaurant-Broadford_Isle_of_Skye_The_Hebrides_Scotland.html"
                    },
                    {
                        "name": "Red Roof",
                        "price": 3,
                        "description": "",
                        "link": "https://www.tripadvisor.com/Restaurant_Review-g635693-d2155359-Reviews-Red_Roof-Glendale_Isle_of_Skye_The_Hebrides_Scotland.html"
                    }
                ]
            },
            {
                "title": "Day 4",
                "subtitle": "Isle of Skye & Glenfinan",
                "description": <div>
                    Departing from the location of your accommodation (which we recommend to be around Portree or on the small island of
                    Raasay), the second day in the island will start with a visit to the <b>Dunvegan Castle & Gardens</b>. This visit should take you the entire morning in this area.
                    If you couldn't make it the day before, take a time to visit the <b>Neist Point Lighthouse</b> before heading to the <b>Fairy Pools</b>.
                    This are small waterfalls, with a magnificent mountain landscape on the coast, definitely one of our favorite places in all of Scotland.
                    <br/><br/>
                    In the middle of the afternoon, take the ferry from <b>Armadale</b> to <b>Mallaig</b> in order to optimize your route and make better use of the landscapes of one of
                    the lesser-known areas of the island. From Mallaig follow the road to <b>Glenfinnan</b>, where the famous viaduct featured in
                    the <i>Harry Potter</i> films is located. Take the opportunity to watch the sunset next to Loch Shiel, next to the <b>Glenfinnan Monument</b>,
                    while the sun hides behind the majestic mountains. To end the day, proceed to <b>Fort William</b> where you will spend the night.
                    <br/><br/>
                    <div className="col-12 col-md-11 col-lg-10 m-auto">
                        <img width="100%" src="https://images.unsplash.com/photo-1563908865396-d51d56bfc94c?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1789&q=80" />
                    </div>
                    <Figure.Caption className="text-center">Glennfinnan Monument and Loch Shiel.</Figure.Caption>
                    <br/>
                    You can catch the famous <i>Harry Potter</i> movie <b>train</b> from Fort William to Mallaig and back. However,
                    it will occupy a large part of your day and hence is not part of our suggested itinerary. But if you are
                    a fan, take a look at the schedules and consider joining this on your route.
                </div>,
                "accommodation": [
                    {
                        "name": "Glen Nevis Youth Hostel",
                        "price": 2,
                        "description": "Located at the base of Ben Nevies, this hostel has access to a multitude of hiking trails.",
                        "link": "",
                        "image": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/160721662.webp?k=e3ffa7bf6920d175e2f4b03539bf8f06f1fe774822a03d418ed6302c71207e95&o="
                    },
                    {
                        "name": "Moorings Hotel",
                        "price": 3,
                        "description": "Situated at the banks of the Caledonian Canal, a 10 min drive to Fort Williams. The rooms offer panoramic views over Ben Nevis and Aonach Mor.",
                        "link": "",
                        "image": "https://cf.bstatic.com/xdata/images/hotel/max1280x900/126981807.webp?k=c828b6940b02e1ffc301a6f489040e4b1e08350c3c6c18708a952a72456ee7c2&o="
                    }
                ]
            },
            {
                "title": "Day 5",
                "subtitle": "Glencoe & Loch Lomond",
                "description": <div>
                    Starting the day very early, take a short trip to <b>Ben Nevis</b>. It is the highest mountain in the British Isles.
                    Continuing the journey, calmly enjoy the landscapes of the <b>Glencoe</b> valley. This are known is known for its waterfalls and
                    trails that climb peaks like <i>Buachaille Etive Mor</i> and <i>Bidean nam Bian</i>.
                    <br/><br/>
                    Take a lunch in the area and continue on your way to <b>Loch Lomond & The Trossachs National Park</b>, where you can spend the rest of
                    the day exploring the area. This is one of the favourite places between the locals to rest. We recommend you to do
                    some particular stops in the area. Namely, go look for the <b>Falls of Falloch</b> and do a short walk to the <b>Kilchurn Castle</b>. Finally,
                    go to enjoy dinner in <b>Luss</b>, a little village at the shores of the lake, considered one of the most beautiful villages in the country.
                    <br/><br/>
                    <div className="col-12 col-md-11 col-lg-10 m-auto">
                        <img width="100%" src="https://cdn.pixabay.com/photo/2021/01/26/20/09/landscape-5952838_1280.jpg" />
                    </div>
                    <Figure.Caption className="text-center">Loch Lomond & The Trossachs National Park.</Figure.Caption>
                    <br/>
                    Despite being 5 days, with so much to see, you will feel that all the time will fly really fast. So, if you have the
                    opportunity to spend a few more days in the area and see everything much more calmly, we certainly recommend it!
                </div>,
                "restaurants": [
                    {
                        "name": "The Real Food Cafe",
                        "price": 1,
                        "description": "",
                        "link": "https://www.tripadvisor.pt/Restaurant_Review-g551950-d1507534-Reviews-The_Real_Food_Cafe-Tyndrum_Loch_Lomond_and_The_Trossachs_National_Park_Scotland.html"
                    },
                    {
                        "name": "The Village Rest",
                        "price": 1,
                        "description": "Good food at cheap prices.",
                        "link": "https://www.tripadvisor.pt/Restaurant_Review-g551943-d1486642-Reviews-The_Village_Rest-Luss_Loch_Lomond_and_The_Trossachs_National_Park_Scotland.html"
                    },
                    {
                        "name": "The Pier Cafe",
                        "price": 2,
                        "description": "Great location at the shore of Loch Katrine",
                        "link": "https://www.tripadvisor.pt/Restaurant_Review-g551948-d6754913-Reviews-The_Pier_Cafe-Stronachlachar_Loch_Lomond_and_The_Trossachs_National_Park_Scotland.html"
                    },
                    {
                        "name": "Loch Lomond Arms",
                        "price": 2,
                        "description": "",
                        "link": "https://www.tripadvisor.pt/Restaurant_Review-g551943-d3393775-Reviews-Loch_Lomond_Arms-Luss_Loch_Lomond_and_The_Trossachs_National_Park_Scotland.html"
                    }
                ]
            }
        ]
    }
}
